<template>
    <v-container>
        <v-snackbar
        :color="color"
        :timeout="unverified ? 60000 : 5000"
        top
        v-model="alert"
        id="snackbar"
        >
          <v-row>
            {{text}}
            <v-spacer></v-spacer>
            <v-btn v-if="unverified" small text color="blue" @click="$router.push({ name: 'verify', params: { email: email } })">Verify</v-btn>
            <v-icon small color="white" @click="alert = false" class="ml-3">mdi-close</v-icon>
          </v-row>
        </v-snackbar>
        <v-row style="min-height: 80vh" class="auth-form-cont" justify="center" align="center">
          <v-col cols="12" sm="9" md="4">
            <v-card>
              <v-card-title
              class="headline secondary white--text font-weight-regular"
              primary-title
              >
              Login
              </v-card-title>
              <v-form
              ref="form"
              v-model="valid"
              v-on:submit.prevent
              >

                <v-card-text>
                  <v-text-field
                  label="Email"
                  id="login-email"
                  v-model="email"
                  type="email"
                  prepend-icon="mdi-email"
                  :rules="[v => !!v || 'Email is required', v => /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(v) || 'E-mail must be valid']"
                  color="secondary"
                  >
                  </v-text-field>

                  <v-text-field
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  label="Password"
                  id="login-password"
                  v-model="password"
                  :type="show ? 'text' : 'password'"
                  @click:append="show = !show"
                  prepend-icon="mdi-lock"
                  :rules="[v => !!v || 'Password is required', v => v.length >= 8 || 'Password must be at least 8 characters']"
                  color="secondary"
                  >
                  </v-text-field>
                  <v-row justify="space-between">
                    <v-btn text color="blue" x-small @click="dialog = true" class="mt-5">Forgot your password ?</v-btn>
                  </v-row>
                </v-card-text>
                  <v-card-actions>
                    <router-link :to="{ name: 'register'}" class="blue--text"><span>Create an account</span></router-link>
                    <v-spacer></v-spacer>
                    <v-btn type="submit" id="login-btn" tile color="secondary" @click="submit" :disabled="!valid" :loading="loading">Login</v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
        <v-dialog
        v-model="dialog"
        width="500"
        >
          <v-card>
            <v-card-title
            class="secondary white--text"
            primary-title
            >
              Forgot your password ?
              <v-spacer></v-spacer>
              <v-btn icon color="white" @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-form
            ref="form1"
            v-model="valid1"
            v-on:submit.prevent
            >
              <v-card-text>
                Enter your email and we will send you a code that you can reset your password with
                <v-text-field
                label="Email"
                v-model="email1"
                type="email"
                id="reset-passsword-email"
                prepend-icon="mdi-email"
                :rules="[v => !!v || 'Email is required', v => /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(v) || 'E-mail must be valid']"
                color="secondary"
                autocomplete="off"
                >
                </v-text-field>
              </v-card-text>

              <v-card-actions>
                <v-btn
                color="secondary"
                tile
                block
                type="submit"
                id="reset-password-btn"
                :disabled="!valid1"
                :loading="loading1"
                @click="send"
                >
                  Send
                </v-btn>
                </v-card-actions>
            </v-form>
          </v-card>

        </v-dialog>
    </v-container>
</template>

<script>
import { resetPasswordCode, login, headersNoAuth } from '../links'

export default {
  title: '-Login',
  data () {
    return {
      alert: false,
      valid: true,
      valid1: true,
      dialog: false,
      color: '',
      text: '',
      show: false,
      loading: false,
      loading1: false,
      email: '',
      email1: '',
      password: '',
      remember: false,
      prevRoute: null,
      unverified: false
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  watch: {
    email (newVal) {
      this.email1 = newVal
    }
  },
  methods: {
    send () {
      this.loading1 = true
      this.$refs.form1.validate()
      if (this.valid1) {
        this.$http.post(resetPasswordCode, { email: this.email1 }, { headers: headersNoAuth() }).then(response => {
          if (response.body.status === false) {
            this.alert = true
            this.color = 'error'
            this.text = response.body.message
            this.loading1 = false
          } else {
            this.$router.push({ name: 'resetPassword', params: { email: this.email1 } })
          }
        }).finally(() => { this.loading1 = false })
      }
    },
    submit () {
      this.loading = true
      this.$refs.form.validate()
      if (this.valid) {
        const body = {
          email: this.email,
          password: this.password
        }
        this.$http.post(login, body, { headers: headersNoAuth() }).then(response => {
          if (response.body.status === false) {
            this.loading = false
            this.alert = true
            this.color = 'error'
            this.text = response.body.message
          } else if (response.body.status === 304 || response.body.status === 302) {
            this.loading = false
            this.alert = true
            this.color = 'warning'
            this.text = response.body.message
            this.unverified = true
          } else {
            this.$store.dispatch('setUser', response.body.data.user)
            localStorage.setItem('userId', response.body.data.user.id)
            this.$cookies.set('userToken', response.body.data.token.access_token, response.body.data.token.expire_in)
            if (this.$store.state.mode) localStorage.setItem('token', response.body.data.token.access_token)
            setTimeout(() => {
              if (!this.$cookies.isKey('userToken')) {
                this.$store.dispatch('removeUser')
                this.$cookies.remove('userToken')
                localStorage.removeItem('userId')
              }
            }, response.body.data.token.expire_in * 1000)
            this.loading = false
            if (this.prevRoute.name === 'book') this.$router.push({ name: 'book', params: { id: this.prevRoute.params.id, search: this.prevRoute.params.search } })
            else if (this.prevRoute.name === 'hotelBook') this.$router.push({ name: 'hotelBook', params: this.prevRoute.params })
            else if (this.prevRoute.name === 'resetPassword' || this.prevRoute.name === 'register' || this.prevRoute.name === 'mail') this.$router.push({ name: 'Home' })
            else if (!this.prevRoute.name) this.$router.push({ name: 'Home' })
            else this.$router.go(-1)
          }
        }, () => {
          this.alert = true
          this.color = 'warning'
          this.text = 'Something went wrong please try again in a few seconds'
          this.loading = false
        })
      }
    }
  },
  beforeCreate () {
    if (this.$cookies.isKey('userToken')) this.$router.push({ name: 'profile' })
  }
}
</script>

<style>
</style>
